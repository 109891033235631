tvd-comparer-component {
  table {
    width: 100%;
  }

  tr.comparer-head-row {
    position: sticky;
    z-index: 2;
    top: 0;

    padding: 0.25em;
    font-family: var(--sl-font-sans);
    font-size: var(--sl-font-size-large);

    th {
      background-color: var(--sl-color-neutral-200);
      padding: 0.25em 0;
      height: 34px;

      &:first-of-type {
        background-color: var(--sl-color-neutral-100);
      }
    }

    small {
      font-size: 70%;
      font-weight: 400;
      &::before {
        content: " / ";
      }
    }
  }

  tr {
    .comparer-header {
      font-size: 1em;
      line-height: 1.15em;
      margin-top: 0;
      flex: 1 0 10%;
      vertical-align: top;
      position: sticky;
      top: 2.6em;
      z-index: 1;
      background-color: var(--sl-color-neutral-100);
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;

      @media screen and (max-width: 1700px) {
        font-size: 0.9em;
      }
    }

    .comparer-code {
      font-family: "Courier New", Courier, monospace;
      font-size: 12px;
      width: 45%;
      white-space: pre;
      padding: 0.25em;
      background-color: var(--sl-color-neutral-50);

      .stroked {
        text-decoration: line-through;
      }

      .comparer-line-modify {
        background-color: var(--sl-color-warning-100);
      }

      .comparer-line-missing {
        background-image: repeating-linear-gradient(
          -66deg,
          var(--sl-color-neutral-50) 0,
          var(--sl-color-neutral-50) 2px,
          var(--sl-color-neutral-200) 2px,
          var(--sl-color-neutral-200) 3px,
          var(--sl-color-neutral-50) 4px
        );
        background-attachment: fixed;
      }

      .comparer-line-add {
        background-color: var(--sl-color-success-200);
      }

      .comparer-line-remove {
        background-color: var(--sl-color-danger-200);
      }
    }
  }
}
