.hs-margin {
  h2:first-of-type {
    margin-top: 0;
  }

  .notifications-section {
    h3 {
      margin: 0 0 0.5em 0;
    }
  }

  ul.no-bullets {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
  }

  .link-3-spans div.notification-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--sl-color-gray-200);

    link-element,
    span {
      flex: 1 0 20%;
      &.date {
        flex: 1 0 150px;
      }
    }

    em {
      font-size: 90%;
      color: var(--sl-color-gray-500);
    }

    &:hover {
      background-color: var(--sl-color-gray-100);
    }

    &:first-child {
      border-top: 1px solid var(--sl-color-gray-200);
    }
  }

  span {
    font-size: 90%;
    strong {
      font-size: 115%;
    }
  }

  .link {
    font-size: 90%;

    &::before {
      color: var(--sl-color-primary-600);
      content: "→ ";
      margin-left: 0.5em;
    }
  }
}
