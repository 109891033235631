tvd-community-component {
  h2 {
    margin-bottom: 0.4em;
  }

  .my-cloud-search-input {
    margin-bottom: 1em;
  }

  .tvd-table.tvd-table-smart-table {
    h2 {
      font-size: 1em;
      line-height: 2em;
    }
  }

  .fields-chks {
    margin-bottom: 1.5em;

    sl-checkbox {
      margin-right: 1em;
    }
  }

  form {
    margin-bottom: 1em;
  }
}
