.login-screen-interactive {
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    margin-top: 0;
  }

  .login-screen-split {
    width: 790px;
    min-height: 380px;
    max-width: 100%;
    height: auto;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border: 1px solid var(--sl-color-neutral-200);
    border-radius: var(--sl-border-radius-large);
    background-color: var(--sl-color-neutral-0);

    h3 {
      margin: 0;
      font-size: 1em;
      text-align: center;
    }

    .login-form-part,
    .login-slider,
    .login-new-here-part {
      position: absolute;
      top: 0;
      height: 100%;
      box-sizing: border-box;
      padding: var(--sl-spacing-large);
      border-radius: var(--sl-border-radius-large);
      transition: opacity 0.25s ease-out;
      text-align: center;

      .form-fields-row {
        text-align: left;
      }

      h2 {
        text-align: center;
      }
    }

    .login-slider {
      transition: left 0.5s ease-out;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .login-form-part {
      left: 0;
      width: 60%;
      background-color: var(--sl-color-neutral-0);
    }

    .new-here-text {
      margin-bottom: 1em;
    }

    .login-slider {
      left: 60%;
      width: 40%;
      text-align: center;
      z-index: 10;

      background-color: var(--sl-color-primary-700);
      color: var(--sl-color-neutral-100);

      a {
        color: var(--sl-color-primary-300);
      }

      sl-button::part(base) {
        background-color: var(--sl-color-neutral-0);
        color: var(--sl-color-primary-700);
      }

      .card-1 {
        opacity: 1;
        transition: opacity 0.5s ease-out;
        display: block;
        max-width: 22ch;
      }

      .card-2 {
        opacity: 0;
        display: none;
        max-width: 22ch;
      }
    }

    .login-new-here-part {
      text-align: center;
      width: 60%;
      right: 0;
      pointer-events: none;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      form {
        margin-bottom: 0;
      }
    }
  }

  .sl-theme-dark & {
    .login-screen-split {
      .login-slider {
        background-color: var(--sl-color-primary-200);
        color: var(--sl-color-neutral-900);

        a {
          color: var(--sl-color-primary-300);
        }

        sl-button::part(base) {
          background-color: var(--sl-color-neutral-0);
          color: var(--sl-color-neutral-800);
          border-color: var(--sl-color-neutral-0);
        }
      }
    }
  }

  &[data-show="newAccount"] {
    .login-form-part {
      opacity: 0;
      pointer-events: none;
    }

    .login-slider {
      left: 0;
      opacity: 1;
      pointer-events: all;

      .card-1 {
        display: none;
        opacity: 0;
      }
      .card-2 {
        opacity: 1;
        display: block;
      }
    }

    .login-new-here-part {
      pointer-events: all;

      transition-timing-function: ease-out;
      animation-duration: 0.5s;
      animation-name: fadeIn;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  @media screen and (max-width: 700px) {
    display: block;
    .login-screen-split {
      position: relative;
      top: auto;
      left: auto;
      width: 100%;
      transform: none;

      .login-form-part,
      .login-slider,
      .login-new-here-part {
        position: static;
        top: auto;
        left: auto;
        right: auto;
        opacity: 1;
        display: block;
        width: 100%;
      }

      .login-slider {
        display: none;
      }
    }
  }
}

/* MFA box */
.login-mfa-holder {
  text-align: center;
  sl-qr-code {
    display: block;
    margin: 1em auto;
    max-width: 100%;
  }
  p,
  label,
  sl-input {
    text-align: left;
  }

  code {
    font-family: inherit;
    background-color: var(--sl-color-neutral-200);
    color: var(--sl-color-neutral-700);
    display: inline-block;
    padding: 0.25em 0.5em;
    border-radius: 20px;
    font-size: small;
    margin-bottom: 0.75em;
  }
}

/* Logged in form */
.loggedin-user-sections {
  display: flex;
  margin: 0 auto 0 auto;
  gap: 1em;

  h4 {
    margin: 0;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }

  .loggedin-user-section {
    flex: 1 1 20%;

    ul {
      padding-left: 1em;
    }
  }

  p.hlp-description {
    max-width: 40ch;
    margin: 0.1em 0 1em 0;
    font-size: 0.9em;
    color: var(--sl-color-neutral-600);
  }
}

.login-new-here-part {
  .oss-card {
    position: relative;
  }
}

/* H1 as Icon of TVD */
.tvd-h1-icon {
  width: auto;
  height: 70px;
  display: inline-block;
  margin: 12px auto -6px auto;
}

.plan-expiration-message {
  color: var(--sl-color-neutral-700);
  strong {
    color: var(--sl-color-neutral-900);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
