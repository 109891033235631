tvd-my-cloud-component {
  tf-select#actions {
    margin: 0;
    text-align: left;
  }

  .files-actions {
    text-align: left;
  }

  .smart-table-column-selector {
    order: 1;
  }

  // .smart-table-reload-btn {
  //   order: 4;
  // }

  .header-pseudo-slot {
    order: 2;
  }

  .top-actions {
    order: 4;
  }

  .smart-table-search {
    order: 3;
  }
}

.confirm-holder {
  padding-left: 1.25em;
  margin-top: 0.5em;
}

.small-info {
  font-size: 0.8em;
  color: var(--sl-color-warning-700);
  text-align: left;
  margin: 0 auto 0 2em;
}
