.click-outside {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--sl-overlay-background-color);
  z-index: 9;

  &[data-opened="1"] {
    display: block;
  }
}

ul.ul-main-menu {
  list-style: none;
  padding: 0;
  margin: 1em 0 0 0;
  z-index: 10;
  font-size: 1.25em;

  li {
    text-align: center;
    position: relative;

    button {
      color: var(--sl-color-neutral-700);
    }

    .main-option {
      width: 74px;
      border: none;
      background: none;
      padding: 0;
      margin-bottom: 5px;

      sl-icon {
        transform: scale(1.4);
        pointer-events: none;

        &.custom-icon {
          fill: var(--sl-color-neutral-700);
          stroke: var(--sl-color-neutral-700);
        }
      }

      &[variant="primary"] {
        sl-icon.custom-icon {
          fill: var(--sl-color-primary-700);
          stroke: var(--sl-color-primary-700);
        }
      }
    }

    sl-menu {
      position: absolute;
      left: 100%;
      top: 0;
      display: none;
      z-index: 10;
      text-align: left;
      overflow: visible;

      &[data-opened="1"] {
        display: block;
      }

      &::before {
        z-index: 10;
        content: "";
        display: block;
        border: 12px solid transparent;
        border-right-color: var(--sl-panel-background-color);
        width: 0;
        height: 0;
        position: absolute;
        top: 10px;
        left: -22px;
      }

      sl-menu-item * {
        pointer-events: none;
      }
    }
  }
}

.menu-avatar {
  margin: 15px 0 18px 0;
  display: inline-block;

  sl-avatar {
    --size: 40px;
  }
}

.menu-chat {
  position: absolute;
  bottom: calc(1em + 40px);
  font-size: 1.5em;
  width: 42px;
  height: 42px;
}

.sl-theme-dark .tvl-icon-community::part(label) {
  filter: invert(100%);
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.offline-icon {
  color: var(--sl-color-warning-600);
  font-size: 1.5rem;
}
