.fileMap-main-part {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  justify-items: center;

  .boxed-part {
    border: none;
    background-color: var(--sl-color-neutral-0);
    border-radius: 2em;
    margin: 0 auto 0 auto;
    position: relative;
    flex: 0 1 auto;
    text-align: center;
    padding: 0.5em 1.5em;
  }

  .source-part {
    margin-bottom: 3em;

    p.date {
      margin: 0.25em 0 0 0;
      font-size: 80%;
      color: var(--sl-color-neutral-600);
    }

    &.isClone {
      p.date {
        margin-bottom: 0.5em;
      }
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -3em;
      left: 50%;
      height: 3.5em;
      width: 1px;
      border-left: 2px solid var(--sl-color-neutral-0);
    }
  }

  .current-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2,
    h3 {
      margin: 0.5em auto 0.5em auto;
    }

    h3 {
      margin: 0 auto 1em auto;
      color: var(--sl-color-neutral-700);
      sl-icon {
        margin-right: 0.35em;
        font-size: 80%;
      }
    }

    .current-buttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0.5em 0 1em 0;
    }

    dictionary-display-element {
      margin-bottom: 0.5em;
    }
  }

  .consumers-part {
    margin-top: 3em;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -3em;
      left: 50%;
      height: 3.5em;
      width: 1px;
      border-left: 2px solid var(--sl-color-neutral-0);
    }

    .title {
      color: var(--sl-color-neutral-600);
    }

    .ul-consumers {
      list-style: none;
      padding: 0;
      margin: 0.5em 0;
      text-align: left;
      width: auto;
      max-width: 100%;
      min-width: 700px;

      li {
        display: flex;
        height: 2.3em;

        align-items: center;
        gap: 1em;
        border-bottom: 1px solid var(--sl-color-gray-200);

        &:first-child {
          border-top: 1px solid var(--sl-color-gray-200);
        }

        .org-name {
          flex: 1 0 35%;
        }

        .vessel-name {
          flex: 1 0 25%;
        }

        .date {
          font-size: 80%;
          color: var(--sl-color-neutral-600);
          flex: 1 0 15%;
          text-wrap: nowrap;
        }

        .actions {
          flex: 1 0 90px;
          text-align: right;
        }
      }
    }
  }
}
