.subtitle {
  font-size: 1.2em;
}

h4 {
  font-weight: 400;
}

.side-view-section {
  .side-view-details {
    margin-bottom: 1em;

    .side-view-details-inputs {
      display: flex;
      gap: 1em;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      & > * {
        flex: 1 0 10%;
      }

      @media screen and (max-width: 1600px) {
        & > * {
          flex: 1 0 15%;
        }
      }

      @media screen and (max-width: 1200px) {
        & > * {
          flex: 1 0 20%;
        }
      }

      @media screen and (max-width: 1024px) {
        & > * {
          flex: 1 0 40%;
        }
      }

      @media screen and (max-width: 660px) {
        flex-direction: column;
      }
    }
  }
  .sideview-action-move {
    g[data-action="move"] {
      cursor: grabbing;

      &:hover use {
        filter: hue-rotate(90deg);
      }
    }
  }
}

//*[@id="st-main-content"]/tvd-view-json-component/sl-drawer/div[2]/section[2]/svg/g[7]/use[3]

.bay-slots-editor {
  overflow-x: auto;
  overflow-y: hidden;

  background-color: var(--sl-color-gray-200);
  box-sizing: border-box;

  padding: 1em 1em 1em 1em;
  text-align: center;

  tf-select {
    margin-left: 0;
    text-align: left;
  }

  .vessel-slots-holder {
    position: relative;
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 1em 3px;
    padding: 10px 10px;
    border: 2px solid var(--sl-color-neutral-0);
    border-left: none;
    border-right: none;
    box-sizing: border-box;
    margin: 0 70px 0 60px;
    background-color: var(--sl-color-gray-300);

    &::before,
    &::after {
      content: attr(data-bow);
      color: var(--sl-color-gray-400);
      font-size: 80%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -2px;
      bottom: -2px;
      right: -100px;
      width: 100px;
      background-color: var(--sl-color-neutral-0);
      border: 2px solid var(--sl-color-neutral-0);
      border-radius: 0 100% 100% 0;
    }

    &::before {
      content: attr(data-stern);
      right: auto;
      left: -60px;
      width: 60px;
      border-radius: 10px 0 0 10px;
      padding-right: 0;
    }

    &::after {
      clip-path: ellipse(100px 52px at 0% 50%);
    }
  }

  .slot-holder {
    position: relative;
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: center;

    .slot-box {
      padding: 0.5rem;
      box-sizing: border-box;
      border: 2px solid var(--sl-color-gray-400);
      background-color: var(--sl-color-gray-100);
      display: flex;
      color: var(--sl-color-gray-800);
      font-size: 0.7em;
      height: 75px;
      width: 50px;
      align-items: flex-start;
      justify-content: center;
      border-radius: 2px;
      text-align: center;
      position: relative;
    }

    .part-slot-edit-btn {
      position: absolute;
      bottom: 1px;
      right: 1px;
    }

    &.bay {
      .slot-box {
        border-width: 1px;
        width: 40px;
        align-items: center;
      }
    }
  }
}

.parts-legend {
  list-style: none;
  padding: 0;
  margin: 1em 0;
  display: flex;
  gap: 0.5em;
  align-items: center;

  li {
    font-size: 0.7em;
    padding: 0.5em 1em;
    color: var(--sl-color-gray-800);
    background-color: var(--sl-color-gray-100);
  }
}
