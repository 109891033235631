.margin-left-05 {
  margin-left: 0.5em;
}

.margin-right-05 {
  margin-left: 0.5em;
}

.slot-actions {
  tf-select {
    margin-left: 0;
  }
}

.bayView-options {
  display: inline-flex;
  gap: 0.5em;
  align-items: center;
  justify-content: space-between;
}

.main-edit-drawer {
  --size: calc(100vw - 90px);
  height: 100vh;
}

.slot-legend-list {
  display: flex;
  list-style: none;
  margin: 1em auto 1.5em;
  gap: 5px;
  justify-content: center;

  &:before {
    content: attr(data-title);
    font-size: 11px;
    text-transform: uppercase;
    color: var(--sl-color-neutral-500);
    padding: 3px 0;
  }

  li {
    padding: 2px 9px;
    font-size: 12px;
  }
}

.bays-wrapper {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  will-change: contents;

  &.cSize-SMALL {
    padding-top: 10px;
  }

  .block-bays {
    display: contents; // This is to display one after the other

    gap: 20px;
    flex-direction: column;
    align-items: center;
    align-content: center;

    bay-box-component::part(bay-arrow-direction) {
      transition: transform 0.3s ease-in-out;
    }
  }

  bay-box-component {
    &::part(box) {
      position: relative;
    }

    &::part(title) {
      text-align: center;
      font-size: 1em;
      height: 24px;
      margin: 0;
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
    }

    &::part(edit-btn) {
      position: absolute;
      right: 10px;
      top: 0;
    }

    &::part(svg-bay) {
      position: absolute;
      left: 0;
      top: 60px;
    }

    &::part(details-holder) {
      text-align: center;
      display: flex;
      justify-content: center;
    }

    &::part(stack-weights) {
      position: absolute;
      left: 0;
      right: 0;
      top: 60px;
    }

    &::part(stack-weight) {
      position: absolute;
      background: var(--sl-color-neutral-100);
      color: var(--sl-color-neutral-800);
      font-size: 8px;
      padding: 3px;
      text-align: center;
      box-sizing: border-box;
      border-radius: 0 0 5px 5px;
    }
  }

  .icon-big {
    font-size: 1.25em;
  }
}

[data-group-paired-bays="true"] .bays-wrapper {
  padding-top: 0;

  .block-bays {
    display: flex; // This is to display grouped by pairs
    background-color: var(--sl-color-neutral-50);
    padding: 10px 0;
    border-radius: 8px;
    margin-bottom: 10px;

    bay-box-component::part(bay-arrow-direction) {
      transform: rotate(90deg);
    }
  }
}

.side-view {
  svg {
    width: 100%;
    height: auto;
    max-height: 320px;
    margin: 0 auto;
  }
}

.bay-edit-components {
  display: flex;
  margin-top: 1em;
  gap: 2em;

  & > * {
    flex: 1 0 40%;
  }

  tvd-bay-edit-component {
    tedivo-interactive-grid.svg-bay-edit {
      min-width: 100%;
      max-width: 100%;
      width: auto;
      height: auto;
    }

    .edit-tools {
      margin-bottom: 3em;

      label {
        display: block;
        font-size: 70%;
        text-transform: uppercase;
        color: var(--sl-color-neutral-600);
        margin-bottom: 0.25em;
      }

      .flex-holder-top {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        justify-content: space-between;
        align-items: center;
      }

      .tools-options {
        min-height: 3em;
        margin-top: 0.5em;
        padding-left: 1em;

        .tools-options-inst {
          display: flex;
          justify-content: flex-start;
          align-content: flex-start;
          flex-wrap: wrap;

          sl-radio-group {
            display: flex;
            justify-content: flex-start;
            align-content: flex-start;
            flex-wrap: wrap;
            flex: 1 0 100%;

            sl-radio {
              flex: 0 1 auto;
              --label-size: 0.5em;
              display: inline-block;
              margin: 0 1em 1em 0;
            }
          }

          sl-checkbox {
            flex: 0 1 auto;
            --label-size: 0.5em;
            display: inline-block;
            margin: 0 1em 1em 0;
          }
        }
      }
    }
  }
}

.title-with-action {
  display: flex;
  align-items: center;

  h1 {
    flex-grow: 4;
  }

  sl-spinner {
    font-size: 1rem;
    margin-right: 0.5em;
    vertical-align: middle;
  }
}

.holder-bays title-with-actions-component {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--sl-color-neutral-0);
}

.flex-inputs {
  .rowed-input {
    display: inline-flex;
    align-items: flex-start;

    label {
      display: inline-block;
      font-size: 80%;
      padding: 0 0.2em 0 0.4em;
      background: var(--sl-color-neutral-400);
      border-radius: 10px 5px 0 10px;
      min-width: 18px;
      text-align: center;
    }

    sl-input {
      display: inline-block;
      width: 6em;
    }

    margin-right: 1em;
  }
}

.cg-tools {
  margin-bottom: 1.5em;
  display: flex;
  gap: 1em;
  align-items: center;

  tf-select {
    margin-left: 0;
    margin-right: 0;
  }

  .dropdown-bays {
    position: relative;
    width: 18em;

    sl-button {
      width: 100%;
    }

    .dropdown-bays-close {
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
    }

    .dropdown-bays-menu {
      position: absolute;

      background: var(--sl-panel-background-color);
      border: solid var(--sl-panel-border-width) var(--sl-panel-border-color);
      border-radius: var(--sl-border-radius-medium);
      padding: var(--sl-spacing-medium);
      overflow: auto;
      overscroll-behavior: none;

      width: 100%;
      box-sizing: border-box;
      z-index: 30;
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      gap: 1px;

      li {
        flex: 1 0 20%;
        font-size: 90%;
        text-align: center;
        display: inline-block;
        padding: 0.325em 0;
        border: 1px solid transparent;
        border-radius: var(--sl-border-radius-medium);
        cursor: pointer;
        background-color: transparent;

        &.selected {
          cursor: default;
          background-color: var(--sl-color-neutral-0);
          border-color: var(--sl-color-primary-300);
        }

        &:hover,
        &:focus,
        &:active {
          border-color: var(--sl-color-primary-500);
        }
      }
    }
  }

  sl-button-group {
    flex: 0 1 auto;
  }

  sl-radio-group {
    flex: 0 1 auto;

    sl-radio {
      display: inline-flex;

      &:first-child {
        margin-right: 1em;
      }
    }
  }
}

.helpers-holder {
  flex: 1 0 auto;
  border-left: 1px dotted var(--sl-color-neutral-200);
  padding-left: 1em;
  text-align: left;
  position: relative;

  &.helpers-bays {
    display: inline-flex;
    border-left: none;
    border-right: 1px dotted var(--sl-color-neutral-200);
    margin-right: 0.25em;
    padding-right: 0.5em;
  }
}

.editing-area {
  display: flex;
  gap: 2em;
  align-items: flex-start;

  svg text {
    pointer-events: none;
  }

  .row-highlighted {
    fill-opacity: 0.9;
    background-color: hsl(204, 93%, 93%);

    .sl-theme-dark & {
      background-color: hsl(204, 63%, 20%);
    }
  }
}

.cgsBayView {
  width: 100%;
  min-width: 400px;
  max-width: 800px;
  height: auto;
}

.cgsBayViewMaster {
  width: 100%;
  min-width: 400px;
  max-width: 800px;
  height: auto;
}

.centered {
  text-align: center;
}

@keyframes deleteTr {
  0% {
    opacity: 1;
    max-height: auto;
    background-color: var(--sl-color-neutral-500);
  }

  95% {
    opacity: 0;
    max-height: auto;
  }

  100% {
    opacity: 0;
    max-height: 0;
  }
}

.deleted-tr td {
  display: none;
}

.no-slots-defined {
  position: absolute;
  transform: translateY(50px) translateX(-50%);
  left: 50%;
  font-size: var(--sl-font-size-small);
  color: var(--sl-color-primary-700);
}

.sideview-legend {
  margin: 1em 0 0.5em 0;
  font-size: var(--sl-font-size-x-small);
  display: flex;
  gap: 0.5em;

  .sideview-legend-pairedNone,
  .sideview-legend-pairedFwd,
  .sideview-legend-pairedAft {
    padding: 0.2em 0.75em;
    border: 1px solid var(--sl-color-neutral-400);
    border-radius: 1em;
    color: var(--sl-color-neutral-700);
    background-color: var(--sl-color-neutral-200);
  }

  .sideview-legend-pairedNone {
    border-color: var(--sl-color-violet-400);
    background-color: rgba(167, 139, 250, 0.25);
  }

  .sideview-legend-pairedAft {
    border-color: var(--sl-color-teal-400);
    background-color: rgba(45, 212, 191, 0.25);
  }

  .sl-theme-dark {
    .sideview-legend-pairedNone {
      background-color: rgba(130, 163, 242, 0.25);
    }

    .sideview-legend-pairedAft {
      background-color: rgba(25, 153, 141, 0.25);
    }
  }
}

.deckview-legend {
  margin: 1em 0 0.5em 0;
  font-size: var(--sl-font-size-x-small);
  display: flex;
  gap: 0.5em;

  .deckview-legend-ok,
  .deckview-legend-danger,
  .deckview-legend-warning {
    padding: 0.2em 0.75em;
    border-width: 1px;
    border-style: solid;
    border-radius: 1em;
  }

  .deckview-legend-danger {
    border-color: var(--sl-color-danger-300);
    background-color: rgba(254, 202, 202, 0.4);
  }

  .deckview-legend-warning {
    border-color: var(--sl-color-orange-300);
    background-color: rgba(254, 215, 170, 0.4);
  }

  .deckview-legend-ok {
    border-color: var(--sl-color-green-300);
    background-color: rgb(187, 247, 208, 0.4);
  }

  html.sl-theme-dark & {
    .deckview-legend-danger {
      border-color: var(--sl-color-danger-100);
      background-color: rgba(191, 35, 32, 0.4);
    }

    .deckview-legend-warning {
      border-color: var(--sl-color-orange-100);
      background-color: rgba(200, 70, 18, 0.4);
    }

    .deckview-legend-ok {
      border-color: var(--sl-color-green-100);
      background-color: rgb(29, 133, 65, 0.4);
    }
  }
}

.edit-lids-holder {
  display: flex;
  height: 100%;

  .lids-svg-mainHolder {
    max-width: 400px;
    width: 800px;
    height: calc(100% - 40px);
    overflow-y: scroll;
    position: relative;
  }

  .lids-table-mainHolder {
    height: calc(100% - 40px);
    overflow-y: scroll;
    position: relative;
  }
}

.field50centered {
  padding: 0 25%;

  @media screen and (max-width: 800px) {
    padding: 0;
  }
}

.bottom-base-u {
  u {
    text-decoration: none;
    border-bottom: 1px solid var(--sl-color-sky-300);
  }
}

.versions-on-demand,
.history-on-demand {
  padding-top: 1em;

  .span-action {
    color: var(--sl-color-neutral-600);
  }

  .history-comments {
    background-color: var(--sl-color-teal-50);
    border: 1px solid var(--sl-color-teal-100);
    color: var(--sl-color-neutral-800);
    display: inline-block;
    padding: 0.25em 0.5em;
    border-radius: 0.25em;
  }
}

.card-3d-view {
  position: relative;

  .renderer-container {
    width: 100%;
    height: 400px;

    canvas {
      width: 100%;
      height: auto;
    }

    &[data-active="false"] {
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: " ";
        background-color: var(--sl-color-neutral-200, #f0f0f0);
        opacity: 0.5;
      }
    }
  }

  .btn-activate-3d {
    position: absolute;
    bottom: var(--sl-spacing-2x-large, 2rem);
    right: var(--sl-spacing-2x-large, 2rem);
    z-index: 1;
  }
}

.tvd-table-with-direction {
  tr.paired-1 .td-smt-isoBay,
  tr.paired-2 .td-smt-isoBay {
    position: relative;
  }

  tr.paired-1 .td-smt-isoBay:after,
  tr.paired-2 .td-smt-isoBay:after {
    content: "";
    display: block;
    position: absolute;
    right: calc(50% - 8px);
    bottom: -8px;
    border: 8px solid var(--sl-color-orange-200);
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
  }

  tr.paired-1 .td-smt-isoBay:after {
    bottom: auto;
    top: -8px;
    border: 8px solid var(--sl-color-orange-200);
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
  }
}
