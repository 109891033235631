.oss-top-tools {
  height: 35px;
  display: flex;

  tvd-notifications-component {
    margin-left: 0.25em;
  }

  &[data-logged-in="false"] {
    units-scroller-element {
      display: none;
    }
    tvd-user-options-component {
      display: none;
    }
    tvd-notifications-component {
      display: none;
    }
  }

  .oss-top-message {
    flex-grow: 4;
    vertical-align: middle;

    .account-expired {
      display: none;

      &[data-expired="true"] {
        display: inline-block;
        margin-right: 0.5em;
      }
    }

    .top-title {
      color: var(--sl-color-neutral-600);
    }

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  tf-select {
    margin: auto 0 auto 0.25em;
  }

  tvd-language-sel-element {
    sl-dropdown sl-button {
      width: 60px;
    }
  }
}
