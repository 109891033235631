svg {
  width: 100%;
  height: auto;
  user-select: none;
  cursor: default;
}

.dragSelector {
  position: absolute;
  border-color: var(--sl-color-primary-400, "blue");
  border-style: dashed;
  border-width: 2px;
  width: 100px;
  height: 100px;
  top: 40px;
  left: 40px;
  z-index: 3;
  pointer-events: none;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background-color: var(--sl-color-primary-500, "blue");
    opacity: 0.4;
  }
}

.dragOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 2;
  pointer-events: none;
}
