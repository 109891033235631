$sidebarWidth: 90px;
$sidebarColor: #efebe5;

main {
  .st-sidebar {
    background-color: var(--sl-color-neutral-0);
    box-sizing: border-box;
    padding: 10px 8px;
    width: $sidebarWidth;
    height: 100vh;
    position: fixed;
    z-index: 3;
    border-right: 1px solid var(--sl-color-gray-200);

    .st-main-logo {
      margin: 10px 0 0 10px;
      width: 100%;
      max-width: 50px;
      height: auto;
    }
  }

  .st-content-holder {
    position: relative;
    margin-left: $sidebarWidth;
    width: calc(100% - $sidebarWidth);
    box-sizing: border-box;
    min-height: 100vh;
    overflow: visible;
    background-color: var(--sl-color-neutral-100);
    padding: 15px;
  }
}
